@use '../breakpoints';

.overlay {
    max-width: 100vw;
    padding: var(--overlay-padding-y) var(--overlay-padding-x);
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem; 
    background-color: var(--white);
    z-index: 10;

    &.has-banner {
        --banner-h: 10rem;
        padding-top: calc(calc(var(--overlay-padding-y) / 2) + var(--banner-h));

        &.banner-sm {
            @media screen and (width >= breakpoints.$mobile) {
                padding-top: var(--overlay-padding-y);
            }
        }

        &.banner-md {
            @media screen and (width < breakpoints.$mobile) {
                padding-top: var(--overlay-padding-y);
            }
        }

        .banner {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: var(--banner-h);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: bottom center;
            }

            &.contain {
                img {
                    object-fit: contain;
                }
            }

            &.faded {
                overflow: hidden;

                &::after {
                    height: 5rem;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: block;
                    content: '';
                    background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    pointer-events: none;
                }

                img {
                    opacity: 0.75;
                }
            }

            &.pan-right {
                img {
                    object-position: bottom right;
                }
            }

            &.pan-left {
                img {
                    object-position: bottom left;
                }
            }
        }
    }
    .image-people-bg {
        border-top-right-radius: 2.5rem;
        border-top-left-radius: 2.5rem;
        object-fit: fill;
    }
}

// Simple layout for now
#app {
    height: 100dvh;
    display: grid;
    align-items: end;

    > * {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }

    &.overlay-active {
        .map {
            filter: brightness(80%);
        }
    }

    .map {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        transition: filter .3s ease-in-out;
    }
}

@media screen and (width < breakpoints.$mobile) {
    .overlay:not(.h-full) {
        max-height: 100dvh;
        overflow-y: auto;
    }

    .overlay {
        position: relative;
    }
}

@media screen and (width >= breakpoints.$mobile) {
    .overlay {
        position: absolute;
        right: 4rem;
        bottom: 4rem;
        max-width: 50%;
        box-shadow: 0 .5rem 1.5rem .3rem var(--black-shadow);
        border-bottom-left-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;

        .image-people-bg {
            border-top-right-radius: 2.5rem;
            border-top-left-radius: 2.5rem;
        }
    }
}


