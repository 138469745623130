@use '../breakpoints';

details {
    border-radius: 1.6rem;

    summary {
        display: flex;
        list-style: none;
        align-items: center;
        cursor: pointer;

        .heading {
            .heading-label {
                position: relative;

                &::after {
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 0.75ch);
                    transform: translateY(-50%);
                    content: "\e90c";
                    font-family: 'icomoon';
                    font-size: 1em;
                }
            }
        }

        + * {
            display: block;
            margin-top: .75em;
        }
    }

    summary::-webkit-details-marker {
        display: none;
    }

    summary::marker {
        display: none;
    } /* Latest Chrome, Edge, Firefox */ 

    &:not([open]) summary .heading .heading-label::after {
        transform: translateY(-50%) rotate(270deg);

    }
    &[open] summary .heading .heading-label::after {
        transform: translateY(-50%) rotate(90deg);

    }
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (width < breakpoints.$mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        row-gap: 2.5em;
        
    }

    .logo-someware {
        width: 300px;
        height: 100px;
    }
    
    .contact-info {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        @media screen and (width < breakpoints.$mobile) {
            flex-direction: column;
            width: 300px;
            row-gap: 30px;
        }

        .contact-section {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.1em;
    }

    a {
        text-decoration: none;
        color: black;
        &:hover {
            text-decoration: underline;
            color: var(--primary);
        }
    }
}

.maplibregl-ctrl-attrib {
    font-size: .8em;
    border-radius: 0;
}

.maplibregl-ctrl-attrib-inner   {
    margin-top: 0;
}
