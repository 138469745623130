.map-view {
    border-radius: 2.4rem;
    overflow: hidden;
    display: grid;
    align-items: end;

    &:not(.square) {
        aspect-ratio: 1 / 1.2;
    }

    &.square {
        max-height: 65dvh;
        margin-inline: auto;
        justify-content: center;
        img {
            aspect-ratio: 1 / 1;
        }

        .loading-view {
            display: flex;
            height: 100%;
            min-height: 300px;
            border-radius: .5em;
            aspect-ratio: 1 / 1;
        }

        .no-view {
            display: flex;
            min-height: 300px;
            max-height: 450px;
            aspect-ratio: 1 / 1;

        }

        .street-view {
            display: flex;
            min-height: 300px;
            max-height: 450px;
            border-radius: .5em;
            aspect-ratio: 1 / 1;

        }
    }

    > * {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        user-select: none;
        pointer-events: none;
    }

    .map-actions {
        z-index: 1000;
        margin-left: 1.6rem;
        margin-bottom: 3.2rem;
        width: fit-content;
    }
}

.marker-pin {
    fill: var(--primary)
}
