@use '../breakpoints';
@use '../colors';

.map-nav {
    --layout-padding: 4rem;

    position: absolute;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    padding: var(--layout-padding);

    @media screen and (width < breakpoints.$mobile) {
        // This variable should be set by the UI renderer to dynamically position the bounds (and make sure all controls are visible)
        --layout-padding: 2rem;
        bottom: var(--overlay-offset);
        // right: 0;
    }


    > * {
        display: flex;
        flex-flow: column nowrap;
        width: fit-content;
        margin-inline: auto;
        gap: 1rem;
    }

    .btn {
        font-size: 1.2em;
        color: var(--black);
        background: var(--white);

        @media screen and (width < breakpoints.$tablet) {
            font-size: 1em;
        }

        &:not(:disabled) {
            .icon-user-star {
                .path3::before {
                    color: var(--primary);
                }
            }
            &:hover {
                color: var(--white);
                background-color: var(--primary);

                .icon-user-star {
                    .path3::before {
                        color: var(--white);
                    }
                }
            }

            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 4px var(--primary-shadow);
            }
        }
    }

    .map-menu {
        position: absolute;
        top: 4rem;
        left: -100vw;
        width: 35rem;
        z-index: 20;
        background-color: var(--white);
        border-radius: 1.4rem;
        box-shadow: 0 .5rem 1.5rem .3rem var(--black-shadow);

        &.active {
            left: calc(100% + calc(var(--layout-padding) / 2));
        }

        menu {
            list-style-type: none;
            margin: 0;
            padding: calc( var(--overlay-padding-y) * 2 ) var(--overlay-padding-x) var(--overlay-padding-y);
            display: grid;
            gap: 3.5rem;

            li {
                display: flex;
                align-items: center;
                gap: 1.25ch;

                a {
                    text-decoration: none;
                    color: var(--primary);
                    font-size: 1.05em;
                    font-weight: 700;
                    line-height: 1.4;
                    text-underline-offset: .4rem;

                    &:hover,
                    &:focus-visible {
                        color: var(--primary-dark);
                        text-decoration: underline;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }

                &:focus-within {
                    .chipicon {
                        color: var(--white);
                        background-color: var(--primary);
                    }
                }
            }
        }

        @media screen and (width < breakpoints.$mobile) {
            position: fixed;
            display: grid;
            align-content: center;
            top: 0;
            bottom: 0;
            height: 100dvh;
            width: 100%;

            &.active {
                left: 0;
            }

            menu {
                padding: var(--overlay-padding-y) var(--overlay-padding-x) ;
            }
        }

        .icon-rank {
            position: absolute;
            top: var(--overlay-padding-y);
            left: var(--overlay-padding-x);
            padding: 0;
            margin: 0;
            font-size: 1.7em;
            line-height: 1;
            background: none;
            border: none;
        }

        .btn-close {
            position: absolute;
            top: var(--overlay-padding-y);
            right: var(--overlay-padding-x);
            padding: 0;
            margin: 0;
            font-size: 1.7em;
            line-height: 1;
            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .user-menu {
        display: flex;
        position: absolute;
        overflow: auto;
        top: 4rem;
        left: -100vw;
        width: 35rem;
        z-index: 20;
        background-color: var(--white);
        border-radius: 1.4rem;
        box-shadow: 0 .5rem 1rem .3rem var(--black-shadow);

        &.active {
            left: calc(100% + calc(var(--layout-padding) / 2));
        }

        .list-survey {
            padding: var(--overlay-padding-x);

            .list {
                margin: 1rem;
                height: 20rem;
                overflow-y: auto;
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: none;
                    background-color: var(--white);
                }
                &::-webkit-scrollbar {
                    width: .7rem;
                    background-color: var(--white);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(colors.$black, .2);
                    border-radius: 100vmax;
                }
                .survey-info {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 2rem;
                    .label-eval {
                        display: flex;
                        align-items: center;
                        width: '50%'
                    }
                }
                .btn-update {
                    text-decoration: none;
                    color: var(--primary);
                    font-size: 1.05em;
                    font-weight: 700;
                    line-height: 1.4;
                    text-underline-offset: .4rem;
                    background: none;
                    border: none;
                    cursor: pointer;
                    &:hover,
                    &:focus-visible {
                        color: var(--primary-dark);
                        text-decoration: underline;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        .header-user-info {
            display: flex;
            justify-content: space-between;
            padding: var(--overlay-padding-y) var(--overlay-padding-x) 0;
            margin: 0;
        }

        .user-info {
            display: grid;
            row-gap: .5em;
            position: relative;
            padding:  var(--overlay-padding-y) var(--overlay-padding-x) 0;
        }

        @media screen and (width < breakpoints.$mobile) {
            position: fixed;
            display: grid;
            align-content: center;
            overflow: auto;
            top: 0;
            bottom: 0;
            max-height: 100dvh;
            width: 100%;

            &.active {
                left: 0;
            }
            .header-user-info {
                display: flex;
                justify-content: space-between;
                padding: calc(var(--overlay-padding-y) * 1.2) var(--overlay-padding-x) 0;
            }

            .user-info  {
                padding: 0 24px;
            }

            .list-survey {
                padding: var(--overlay-padding-y) var(--overlay-padding-x) ;  
                .list {
                    margin: 1rem;
                    height: 30rem;
                    overflow-y: auto;
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: none;
                        background-color: var(--white);
                    }
                    &::-webkit-scrollbar {
                        width: .7rem;
                        background-color: var(--white);
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(colors.$black, .2);
                        border-radius: 100vmax;
                    }
                    .survey-info {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 2rem;
                        .label-eval {
                            display: flex;
                            align-items: center;
                            width: '50%'
                        }
                    }
                    .btn-update {
                        text-decoration: none;
                        color: var(--primary);
                        font-size: 1.05em;
                        font-weight: 700;
                        line-height: 1.4;
                        text-underline-offset: .4rem;
                        background: none;
                        border: none;
                        cursor: pointer;
                        &:hover,
                        &:focus-visible {
                            color: var(--primary-dark);
                            text-decoration: underline;
                        }
    
                        &:focus-visible {
                            outline: none;
                        }
                    }
                }     
            }
        }

        .btn-close {
            font-size: 1.7em;
            line-height: 1;
            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .zoom-menu {
        margin-top: 1rem;
        
    }

    .button-logo {
        display: flex;

        .logo-ville-marchable {
            margin-left: 1rem;
            height: 50px;
            width: 360px;
        }
    }
    
}

.info-user {
    display: flex;
    justify-content: space-between;
}