@use '../breakpoints';
@use '../colors';

#app.survey-screen {
    .overlay {
        .survey-actions {
            margin-top: 2rem;
        }
    }
}

@media screen and (width < breakpoints.$mobile) {
    #app.survey-screen {
        height: 100%;

        .overlay {
            border-radius: 0;
            max-width: initial;
        }
    }
}

@media screen and (width >= breakpoints.$mobile) {
    #app.survey-screen {
        align-items: center;
        justify-items: center;

        .overlay {
            position: static;
            min-width: 70%;
            width: 70%;
            max-width: 100%;

            .content {
                max-height: 80dvh;
                overflow-y: auto;
                scrollbar-gutter: stable;

                .survey-body {
                    &.min-h-60 {
                        min-height: 50dvh;
                    }
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: none;
                    background-color: var(--white);
                }
                &::-webkit-scrollbar {
                    width: .7rem;
                    background-color: var(--white);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(colors.$black, .2);
                    border-radius: 100vmax;
                }
            }
        }
    }
}
