.list-select {
    list-style-type: none;
    overflow-y: auto;
    overflow-x: visible;

    &.h-40 {
        max-height: 40vh;
    }
    &.h-60 {
        max-height: 60vh;
    }

    &.has-buttons {
        margin-block: 0;
        padding: 1.5rem;

        li {
            > button,
            > a,
            > details {
                padding: 1.6rem 2.4rem;
                justify-content: center;
                box-shadow: 0 .5rem .2rem .3rem var(--black-shadow);
            }
        }
    }

    &.has-colors {
        li {
            &:nth-child(3n+1) {
                > button,
                > a {
                    border-bottom: 2px solid var(--red);
                }
            }
            &:nth-child(3n+2) {
                > button,
                > a {
                    border-bottom: 2px solid var(--yellow);
                }
            }
            &:nth-child(3n+3) {
                > button,
                > a {
                    border-bottom: 2px solid var(--green);
                }
            }
        }
    }


    &.has-icons {
        margin-block: 1.5rem;
        margin-inline: -1.5rem;
        padding: 1rem;

        li {
            > button,
            > a {
                padding: 1rem;
                justify-content: start;
                line-height: 1;
                gap: 1ch;

                .icon {
                    font-size: 1.5em;
                }

                &.highlight {
                    color: var(--highlight);
                }
            }
        }
    }

    li {
        position: relative;

        > button,
        > a {
            display: flex;
            width: 100%;
            align-items: center;
            text-align: center;
            border: none;
            border-radius: .8rem;
            color: inherit;
            background: none;
            text-decoration: none;
            cursor: pointer;
            transition-property: color, background-color;
            transition-duration: .2s;
            transition-timing-function: ease-in-out;

            span {
                text-underline-offset: .4rem;
            }

            &:hover,
            &:focus-visible {
                color: var(--primary);

                span:not(.icon) {
                    text-decoration: underline;
                }
            }

            &.active {
                color: var(--white);
                background-color: var(--primary-dark);
            }
        }

        > details {
            // height: 100%;

            summary {
                outline-offset: 1.4rem;
                max-width: calc(100% - 10rem);
                line-height: 1.4;
            }
        }

        .answers {
            display: flex;
            position: absolute;
            top: 1rem;
            right: 2.2rem;
            z-index: 10;

            button {
                display: block;
                position: relative;
                padding: 0;
                width: 4rem;
                height: 4rem;
                font-size: 2em;
                line-height: 1;
                border: none;
                background: none;
                cursor: pointer;

                .icon,
                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &::after {
                    display: block;
                    content: '';
                    height: 3.5rem;
                    width: 3.5rem;
                    pointer-events: none;
                    border-radius: 50%;
                    border: 1px solid transparent;
                }

                &:hover,
                &:focus-visible {
                    outline: none;

                    &::after {
                        border-color: var(--black) !important;
                    }
                }

                &.active {
                    &.positive {
                        color: var(--green);

                        &::after {
                            border-color: var(--green);
                        }
                    }
                    &.negative {
                        color: var(--red);

                        &::after {
                            border-color: var(--red);
                        }
                    }
                }
            }
        }
    }
}

.list-select-loading {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
