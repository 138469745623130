@use '../breakpoints';
@use '../colors';

.heading {
    font-size: 1.15em;
    font-weight: 700;
    text-wrap: balance;
}

.label-modal {
    font-weight: 700;
    font-size: 1.2em;
    color: var(--primary);
}

.drag-container {
        display: flex;
        margin-top: -1.5rem;
        justify-content: center;
        align-items: center;
        height: 1rem
}

.drag-component {
    @media screen and (width < breakpoints.$mobile) {
        display: flex;
        width: 4.2rem;
        height: .6rem;
        background-color: var(--gray-400);
        border-radius: 2.5rem;
    }
}